'use strict';

var CassidyAIHelper = require('./CassidyAIHelper');
var footerFindStore = require('./footerFindStore');
var LocationSearch = require('../../../../app_storefront_core/cartridge/js/bopis/components/LocationSearch');
var page = require('../../../../app_storefront_core/cartridge/js/page');

var bootbarnApp = {
    init: function () {
		$.extend(page, window.pageContext);

		footerFindStore.init();

		$(document).on('click', '.my-store-panel .store-view', function(event) {
			$('.my-store-modal').show();
		});

		$(document).on('click', '.my-store-modal .close', function(event) {
			$('.my-store-modal').hide();
		});

		var locationSearchMobileNavigation = new LocationSearch();
		$(document).on('click', '.shop-my-store .show-store-search, .my-store-panel .show-store-search, .my-store-modal .show-store-search', function(event) {
			$('.my-store-modal').hide();
			locationSearchMobileNavigation.initialize($('.location-search-dialog'), $.extend(page.options.Bopis, { Channel: page.options.Channel }));
		});

		$(document).off('Bopis.SelectedStore.Refresh').on('Bopis.SelectedStore.Refresh', function (event, storeId) {
			locationSearchMobileNavigation.updateStoreDetails(storeId);
		});

		$(document).off('Bopis.AssignStoreToProfile').on('Bopis.AssignStoreToProfile', function (event, storeId) {
			locationSearchMobileNavigation.assignStoreId(storeId);
		});

		$(document).on('click', '.shop-my-store .show-my-store-modal', function(event) {
			$('.my-store-modal').show();
		});
    }
};

//initialize app
$(document).ready(function () {
	bootbarnApp.init();
	CassidyAIHelper.init();
});