'use strict';

exports.init = function init () {
	//use jquery Validate
	$('#footer-store-search #zip').rules("add", {
		required: true,
		digits: true,
		minlength: 5,
		messages: {
			required: Resources.VALIDATE_ZIP,
			minlength: Resources.VALIDATE_ZIP
		},
		normalizer: function(value) {
			return $.trim(value);
		}
	});
}